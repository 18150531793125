import useWindowSize from '@src/hooks/useWindowSize'
import { StrapiImageResponsive } from '@src/types/strapi'
import Image, { ImageProps } from 'next/image'

export interface ImageCommonComponentProps extends StrapiImageResponsive {
  imageProps?: Partial<ImageProps>
}

export const ImageCommonComponent = ({ imageProps, ...props }: ImageCommonComponentProps) => {
  const { isMobile } = useWindowSize()
  const img = isMobile ? props.mobile_image : props.desktop_image

  if (!img) return null

  return <Image src={img.url} layout={'fill'} {...imageProps} alt="Imagem" />
}
